<template>
  <div class="classroom-revert">
    <div class="header">
      <div class="filter">
        <!-- <div class="filter-item"
             @click="vanPopupClick('status')">
          <span>{{ filter.status.label }}</span>
          <van-icon class="filter-down"
                    name="arrow-down" />
        </div> -->
        <div class="filter-item"
             @click="showCalendarBtn('single')">
          <span>{{ filter.time.label }}</span>
          <van-icon class="filter-down"
                    name="arrow-down" />
        </div>
      </div>
    </div>
    <div class="main">
      <div class="records">
        <van-list :immediate-check="false"
                  v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  @load="onLoad">
          <div class="record-item"
               v-for="(item, index) in list"
               :key="index">
            <item-card :title="item.classroomName">
              <!-- :status="parseStatus(item.status)" -->
              <div class="content">
                <!-- <div class="content-item">
                  <span class="name">地址:</span>
                  <span class="value"> {{ item.address }}</span>
                </div> -->
                <div class="content-item">
                  <span class="name">课室面积:</span>
                  <span class="value">{{ item.acreage }}m²</span>
                </div>
                <div class="content-item">
                  <span class="name">容纳人数:</span>
                  <span class="value">{{ item.capacity }}</span>
                </div>
                <div class="content-item">
                  <span class="name">课室说明: </span>
                  <span class="value">{{ item.remark }}</span>
                </div>
                <div class="content-item">
                  <span class="name">借用时间: </span>
                  <span class="value line">
                    <template v-for="(items,inds) in item.dates">
                      <div> {{ items.borrowStartTime }}~{{ items.borrowEndTime}}<span v-if="item.dates.length-1!=inds">,</span></div>
                    </template>
                  </span>
                </div>
              </div>
              <template #footer>
                <div class="action">
                  <van-button class="detail-btn"
                              type="info"
                              size="small"
                              round
                              @click="appointmentDetailClick(item)">详情</van-button>
                </div>
              </template>
            </item-card>
          </div>
          <template #loading>
            <div></div>
          </template>
          <template #finished>
            <div v-if="list.length">没有更多了</div>
          </template>
        </van-list>
      </div>
      <van-empty description="暂无数据"
                 v-if="!list.length" />
    </div>
    <div class="footer">
      <!-- <van-button class="add-btn"
                  type="info"
                  round
                  @click="addAppointment">新增预约</van-button> -->
    </div>

    <!-- 选择弹框 -->
    <van-popup v-model="showVanPopupType"
               round
               position="bottom">

      <custom-popup :title="filter.title"
                    :columns="vanPopupTypeColumns"
                    value-key="name"
                    @cancel="vanPopupCancel"
                    @confirm="vanPopupConfirm"></custom-popup>
    </van-popup>
    <!-- 选择日期 -->
    <van-calendar v-model="showCalendar"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :type="calendarType"
                  @confirm="onConfirm" />
  </div>
</template>

<script>
import "@/assets/less/views/classroom/classroomRevert.less";
import { SUCCESS_CODE } from "@/apis/notification";
export default {
  name: "ClassroomAppointmentRecord",
  props: ["personnel"],
  data() {
    return {
      prevStatus: null,
      showVanPopupType: false,
      showCalendar: false,
      calendarType: "",
      filter: {
        status: {
          id: "",
          label: "所有状态"
        },
        time: {
          id: "",
          label: "日期"
        },
        title: "所有状态"
      },
      list: [],
      vanPopupTypeColumns: [],
      minDate: "",
      maxDate: "",
      startTime: "",
      endTime: "",
      listStatus: {
        loading: true,
        finished: false
      },
      hasMore: false,
      page: {
        pageNumber: 0,
        pageSize: 10
      }
    };
  },
  computed: {},
  methods: {
    async getClassroomAppointmentList() {
      let params = {
        status: "APPROVED, USING, EXPIRED, RETURNED, CHECKED", //this.status,
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize
      };
      if (this.startTime && this.endTime) {
        params.startTime = this.startTime + " 00:00";
        params.endTime = this.endTime + " 23:59";
      }

      let ret, list;
      if (this.personnel == "user") {
        ret = await this.$api.getClassroomAppointmentsApplication({
          params
        });
      } else {
        ret = await this.$api.getClassroomAppointmentList({
          params
        });
      }
      if (ret.code != SUCCESS_CODE) {
        this.list = [];
        return;
      }
      if (!this.page.pageNumber) {
        list = ret.data;
      } else {
        list = this.list.concat(ret.data);
      }
      let nowTime = this.$moment().format("YYYY-MM-DD");
      this.list = list.map(val => {
        let dates = val.dates.filter(row => row.borrowDate == nowTime);
        return { ...val, dates };
      });
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },

    handlePagination(totalPages) {
      if (this.page.pageNumber + 1 < totalPages) {
        // 有更多数据
        this.page.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    parseStatus(status) {
      if (status == "APPROVED") {
        return "待开门";
      }
      if (status == "UNDER_REVIEW") {
        return "审核中";
      }
      if (status == "REJECTED") {
        return "已驳回";
      }
      if (status == "USING") {
        return "使用中";
      }
      if (status == "EXPIRED") {
        return "已归还";
      }
    },

    appointmentDetailClick(item) {
      this.$utils.setSession("classroomRevertEdit", JSON.stringify(item));
      this.$router.push({
        path: "/classroomRevertEdit/" + this.personnel,
        query: {
          classroomId: item.id,
          appointmentId: item.appointmentId
        }
      });
    },

    vanPopupClick(type) {
      if (type == "status") {
        this.filter.title = "状态";
        this.vanPopupTypeColumns = [
          { id: "", name: "全部", key: "status" },
          { id: "APPROVED", name: "待开门", key: "status" },
          { id: "UNDER_REVIEW", name: "审核中", key: "status" },
          { id: "REJECTED", name: "已驳回", key: "status" },
          { id: "USING", name: "使用中", key: "status" },
          // { id: "EXPIRED", name: "已归还", key: "status" },
          { id: "RETURNED", name: "已归还", key: "status" },
          { id: "CHECKED", name: "已检查", key: "status" }

          //  CREATED, APPROVED, UNDER_REVIEW, REJECTED, EXPIRED, USING, RETURNED, CHECKED
        ];
      }
      this.showVanPopupType = true;
    },
    vanPopupCancel() {
      this.showVanPopupType = false;
    },
    vanPopupConfirm(val) {
      this.showVanPopupType = false;
      this.status = val.id;
      this.filter[val.key].label = val.name;
      this.filter[val.key].id = val.id;
      this.getInitialData();
    },

    showCalendarBtn(type) {
      this.showCalendar = true;
      this.calendarType = type;
    },
    onConfirm(date) {
      this.showCalendar = false;
      this.filter.time.label = this.$moment(date).format("YYYY-MM-DD");
      this.startTime = this.$moment(date).format("YYYY-MM-DD");
      this.endTime = this.$moment(date).format("YYYY-MM-DD");
      this.getInitialData();
    },
    getInitialData() {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.page.pageNumber = 0;
      this.getClassroomAppointmentList();
    },

    addAppointment() {},
    onLoad() {
      this.getClassroomAppointmentList();
    },
    init() {
      this.minDate = new Date(this.$moment().add(-1, "y").format("YYYY-MM-DD"));
      this.maxDate = new Date(this.$moment().add(1, "y").format("YYYY-MM-DD"));

      this.filter.time.label = this.$moment().format("YYYY-MM-DD");
      this.startTime = this.$moment().format("YYYY-MM-DD");
      this.endTime = this.$moment().format("YYYY-MM-DD");

      this.getClassroomAppointmentList();
    }
  },
  created() {
    this.init();
  }
};
</script>
